import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {CategoryBrand, Brands, BrandsHeader} from "benlux-ecommerce-ui";

import {brand} from "../../../data/dataBrand";
import brandLogo from "../../../assets/lamer-white.png"
import {MenuQuery} from "../../../queries/MenuQuery";

export const BrandsPage = () => (
  <MenuQuery>
    {({menu}) => {
      const brandsCategory = menu.find(c => c.urlPath === '/marques');
      return (
        <div>
          <Helmet>
            <title>Marques</title>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={brandsCategory.name + " ‣ BENLUX"}/>
            <meta property="og:url" content={'https://www.benlux.fr' + brandsCategory.urlPath}/>
            <meta name="twitter:card" content="summary"/>
            <meta property="twitter:domain" content="benlux.fr"/>
            <meta property="twitter:url" content={'https://www.benlux.fr' + brandsCategory.urlPath}/>
            <meta name="twitter:title" content={brandsCategory.name + " ‣ BENLUX"}/>
            <meta name="page-loaded" content="true" />
          </Helmet>
          <BrandsHeader brandLogo={brandLogo}/>
          <CategoryBrand showButton={false} brand={brand}/>
          <Brands brands={brandsCategory.children}/>
        </div>
      )
    }}
  </MenuQuery>
);

BrandsPage.propTypes = {
  id: PropTypes.string.isRequired
};

export default BrandsPage;
